import React from 'react'
import Grid from '@mui/material/Grid'
import './ContactBar.scss'
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import useMediaQuery from "@mui/material/useMediaQuery";
import { ImageList, ImageListItem } from '@mui/material';

const ContactBar = ({setClose}) => {
    const images = [
        `https://templates.hibootstrap.com/xton/default/assets/img/products/img1.jpg`,
        `https://templates.hibootstrap.com/xton/default/assets/img/products/img2.jpg`,
        `https://templates.hibootstrap.com/xton/default/assets/img/products/img3.jpg`,
        `https://templates.hibootstrap.com/xton/default/assets/img/products/img4.jpg`,                
      ];
      const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <>
      <Grid container spacing={0} className="cbar-main">
        <Grid item md={12} xs={12} sx={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"20px"}}>
            <h3><u>About The Store</u></h3>
            <CloseIcon sx={{cursor:"pointer"}}
            onClick={(e)=>{setClose(false)}}
            />
        </Grid>
        <Grid item md={12} xs={12}>
            <p style={{lineHeight:"1.8rem"}}>One of the most popular on the web is shopping. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Grid>
        <Grid item md={12} xs={12} className="cbar-cotcon">
            <div className="row"><LocationOnIcon/> <h4>Wonder Street, USA, New York</h4></div>
            {/* <div className="row"><CallIcon/> <h4>+91 9822 277 288</h4></div> */}
            <div className="row"><EmailIcon/> <h4>hello@jainscreen.com</h4></div>
        </Grid>
        
        <Grid item md={12} xs={12}>
            <h3><u>New Store</u></h3>            
        </Grid>
        <Grid item md={12} xs={12}>
        <ImageList cols={isMobile ? 2 : 4} gap={4} className="image-list">
            {Array.from({ length: 4 }).map((_, index) => {
                const randomIndex = Math.floor(Math.random() * images.length);
                const imageUrl = images[index];

                return (
                <ImageListItem key={index} >
                    <img
                    src={`${imageUrl}?w=248&fit=crop&auto=format`}
                    loading="lazy"
                    alt={`Product ${index}`}
                    />     
                </ImageListItem>     
                );
            })}
            </ImageList>
        </Grid>
        <Grid item md={12} xs={12}>
            <p style={{lineHeight:"1.8rem"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </Grid>
       </Grid>
    </>
  )
}

export default ContactBar