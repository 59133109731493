import {
  Grid,
  Paper,
  TextField,
  InputAdornment,
  CircularProgress,
  Divider,
  Container,
  Drawer,
  Box,
} from "@mui/material";
import React, { useEffect, useState, Suspense, lazy, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FixedLayout from "../Layout/FixedLayout";
import AppBarComponent from "../Appbar/AppBarComponent";
import HomeImageSlider from "../ImageSliders/HomeImageSlider";
import { Footer } from "../Appbar/Footer";
import "./HomePage.scss";
import { PiShippingContainer } from "react-icons/pi";
import { MdCurrencyExchange } from "react-icons/md";
import { HiOutlineBanknotes } from "react-icons/hi2";
import { FaExchangeAlt } from "react-icons/fa";
import CategoryList from "../Product/CategoryList";

const HomePage = () => {
  const navigate = useNavigate();
  const subheadingRef1 = useRef(null);
  const subheadingRef2 = useRef(null);
  const subheadingRef3 = useRef(null);
  const categoryListRef1 = useRef(null);
  const categoryListRef2 = useRef(null);
  const categoryListRef3 = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        }
      });
    }, observerOptions);

    const elementsToObserve = [
      subheadingRef1.current,
      subheadingRef2.current,
      subheadingRef3.current,
      categoryListRef1.current,
      categoryListRef2.current,
      categoryListRef3.current,
    ];

    elementsToObserve.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  return (
    <>
      <FixedLayout header={<AppBarComponent />} footer={<Footer />}>
        <Box sx={{padding:"10px",flex:1,display:"flex",justifyContent:"center"}}>
        <Grid container spacing={0} sx={{display:"flex",justifyContent:"center"}}>
          <Grid item xs={12} md={12} className="b2bheading">            
            <h1 style={{fontSize:"60px"}}>B2B Only</h1>
            <h5 style={{fontSize:"20px"}}>No Direct Sale</h5>
          </Grid>
          <Grid item xs={12} md={12}>
            <HomeImageSlider />
          </Grid>
          <Grid item xs={12} md={12} className="subheading" ref={subheadingRef1}>
            <h5>See Our Collection</h5>
            <h1>Sub Categories </h1>
          </Grid>
          <Grid item xs={12} md={10} className="animated-grid-item">
            <CategoryList/>
          </Grid>
          <Grid item xs={12} md={12} className="subheading" ref={subheadingRef2}>
            <h5>See Our Collection</h5>
            <h1>Sub Categories 2</h1>
          </Grid>
          <Grid item xs={12} md={10} className="animated-grid-item-zoom" ref={categoryListRef2}>
            <CategoryList/>
          </Grid>
          <Grid item xs={12} md={12} className="subheading" ref={subheadingRef3}>
            <h5>See Our Collection</h5>
            <h1>Sub Categories 3</h1>
          </Grid>
          <Grid item xs={12} md={10} className="animated-grid-item-zoom" ref={categoryListRef3}>
            <CategoryList/>
          </Grid>
        </Grid>
        </Box>
      </FixedLayout>

   
    </>
  );
};

export default HomePage;
