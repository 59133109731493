import { createSlice } from "@reduxjs/toolkit";

const errorReducer = createSlice({
  name: "error",
  initialState: {
    errorText: "",
    isError: false,
    errorFieldName: "",
    errorData: [],
  },

  reducers: {
    setErrorText(state, action) {
      state.errorText = action.payload;
    },
    setIsError(state, action) {
      state.isError = action.payload;
    },
    setErrorFieldName(state, action) {
      state.errorFieldName = action.payload;
    },
    setErrorData(state, action) {
      state.errorData.push(action.payload);
    },
    setClearErrorData(state, action) {
      state.errorData = action.payload;
    },
  },
});

export const {
  setErrorText,
  setIsError,
  setErrorFieldName,
  setErrorData,
  setClearErrorData,
} = errorReducer.actions;
export default errorReducer.reducer;

export const selectErrorData = (state) => state.error.errorData;

export const errorSet = (data) => {
  return function actionData(dispatch) {
    dispatch(setErrorData(data));
  };
};
export const errorClear = (data) => {
  return function actionData(dispatch) {
    dispatch(setClearErrorData(data));
  };
};
export const getErrorStatus = (fieldname, isType) => {
  return function actionData(dispatch, getState) {
    const errorData = selectErrorData(getState());
    let resData = errorData.filter((item) => item.fieldName === fieldname);
    if (isType === 0) {
      if (resData.length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (isType === 1) {
      if (resData.length > 0) {
        return resData?.[0]?.errorText;
      } else {
        return "";
      }
    }
  };
};
export const removeErrorStatus = (fieldname) => {
  return function actionData(dispatch, getState) {
    const errorData = selectErrorData(getState());
    let resData = errorData.filter((item) => item.fieldName !== fieldname);
    dispatch(setClearErrorData(resData));
  };
};
export const errorRemove = (data) => {
  return function actionData(dispatch) {
    dispatch(setErrorText(""));
    dispatch(setIsError(false));
    dispatch(setErrorFieldName(""));
  };
};
