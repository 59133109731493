import React from "react";
import "./UserLargeScreenLogin.css";
import { Button, TextField, Grid } from "@mui/material";
import avatar from "../../Assets/avatar with man in green shirt and orange hat.png";
import logsvg from "../../Assets/logback.svg";
import rectangle from "../../Assets/Rectangle.png";
import lamp from "../../Assets/chandelier with green round lampshade.png";
import CloseIcon from "@mui/icons-material/Close";

const UserLargeScreenLogin = ({ className = "" }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          background: "#00523F",
          gap: "50px",
          height: "400px",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            paddingLeft: "20px",
          }}
        >
          <div className="avatar">
            <img
              style={{ width: "200px", height: "80px", objectFit: "contain" }}
              src={avatar}
            />
          </div>
          <p className="welcome">Welcome</p>
          <TextField
            size="small"
            fullWidth
            label="Mobile Number"
            variant="standard"
            type="number"
            sx={{
              "& .MuiInput-root:before": {
                borderBottomColor: "#fff", // Default (not focused) underline color
              },
              "& .MuiInput-root:after": {
                borderBottomColor: "#fff", // Underline color when focused
              },
              "& .MuiInputBase-input": {
                color: "#fff", // Input text color
              },
              "& .MuiInputLabel-root": {
                color: "#fff", // Label color
              },
            }}
          />
          <TextField
            size="small"
            fullWidth
            label="Password"
            variant="standard"
            type="text"
            sx={{
              "& .MuiInput-root:before": {
                borderBottomColor: "#fff", // Default (not focused) underline color
              },
              "& .MuiInput-root:after": {
                borderBottomColor: "#fff", // Underline color when focused
              },
              "& .MuiInputBase-input": {
                color: "#fff", // Input text color
              },
              "& .MuiInputLabel-root": {
                color: "#fff", // Label color
              },
            }}
          />
          <Button variant="contained" className={"login"}>
            Login
          </Button>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "flex-end",
            position: "relative",
          }}
        >
          <img className="background-image" src={logsvg} alt="background" />
          <img src={rectangle} className={`rect`} />
          <img src={lamp} className={`lamp`} />
          <button      
            type="button"      
             style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              color: "#fff", // White icon
              // backgroundColor: "#45c071",
              backgroundColor:"transparent",
              height:"50px",
              width:"50px",
              padding:"5px",
              margin:0,
              textAlign:"center",
              cursor:"pointer",
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              border: "1px solid #fff",
              borderTop:"none",
              borderRight:"none", 
            }}
          >
            <CloseIcon style={{fontSize:"30px"}} />
          </button>
        </div>
      </div>
    </>
  );
};

export default UserLargeScreenLogin;
