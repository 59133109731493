import {
    Grid,
    Paper,
    TextField,
    InputAdornment,
    CircularProgress,
    Divider,
  } from "@mui/material";
  import React, { useEffect, useState, Suspense, lazy } from "react";
  import { useNavigate } from "react-router-dom";
  import FixedLayout from "../Layout/FixedLayout"; 
  import AppBarComponent from "../Appbar/AppBarComponent";
  import HomeImageSlider from "../ImageSliders/HomeImageSlider";
  import ImageCard from "../Product/ImageCard";
  import { Footer } from "../Appbar/Footer";
import ProductCategoryCardList from "./ProductCategoryCardList";
import ProductCardList from "./ProductCardList";
  const ProductPage = () => {
    const navigate = useNavigate();
   
    return (
      <>
        <FixedLayout header={<AppBarComponent/>} footer={<Footer/>}>
          <Grid container spacing={1} justifyContent="center">            
            <Grid item xs={12} md={12} sx={{marginTop:"3%",marginBottom:"3%",marginLeft:"1%"}}>            
              <h1>Products</h1>            
            </Grid>
            <Grid item xs={12} md={10}>
               <ProductCardList/>               
            </Grid>
          </Grid>
        </FixedLayout>
      </>
    );
  };
  
  export default ProductPage;
  