import { createSlice } from "@reduxjs/toolkit"; 
import { setConfirmDialogData } from "./confirmDialogReducer";
import { callAjax, formatDatetime_DMY, formatDatetime_YMD, getImage } from "../../MIS/Global";
import { reloadPage, setAlertData } from "./alertReducer";
import { errorClear, errorSet } from "./errorReducer";
   

const adminProductReducer = createSlice({
    name: 'adminproduct',
    initialState: {
        fabricModal:false,   
        sizeModal:false,
    },

    reducers: {                   
        setFabricModal(state,action){
          state.fabricModal=action.payload;
        },
        setSizeModal(state,action){
            state.sizeModal=action.payload;
        },
                     
    }
});

export const { setFabricModal,setSizeModal} = adminProductReducer.actions;
export default adminProductReducer.reducer;
 



export const handleFabricModal = (data) => {    
    return async function actionData(dispatch,getState) { 
        const {fabricModal}=getState().adminproduct;
        dispatch(setFabricModal(!fabricModal));
    };
  }

  export const handleSizeModal = (data) => {    
    return async function actionData(dispatch,getState) {      
        const {sizeModal}=getState().adminproduct; 
        dispatch(setSizeModal(!sizeModal));        
    };
  }

