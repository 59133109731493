import { Checkbox, FormControlLabel } from "@mui/material";
import React from "react";

const SizeList = () => {
   return (
    <>
      <ul className="horizontal-list">
        {Array.from({ length: 20 }).map((element, index) => {
          return (
            <li key={index}>
              <FormControlLabel
                control={<Checkbox name="gilad" color="success"/>}
                label={`Size - ${index}`}
                
              />
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default SizeList;
