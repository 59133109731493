import React, { useState } from "react";
import AdminLayout from "../Layout/AdminLayout";
import Grid from "@mui/material/Grid";
import NewImagesUpload from "../GlobalComponent/NewImagesUpload";
import { ImageList, Paper, IconButton, ImageListItem } from "@mui/material";
import { useForm } from "react-hook-form";
import "./AdminBanner.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { handleDeleteFiles } from "../../API/Reducer/fileReducer";

const AdminBannerPage = () => {
  const dispatch=useDispatch();
  const { files,previewFiles } = useSelector((state) => state.file)
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      mpid: 0,
    },
  });
  const onSubmit = (data) => {};
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
        <AdminLayout>
          <Grid container spacing={1} p={1}>
            <Grid item md={8} xs={12}>
              <Paper elevation={2} className="banrleft">
                <div className="row">
                  <h3>Banner Master</h3>
                  <NewImagesUpload/>
                </div>
                <div>
                  <ImageList
                    sx={{ width: "100%", height: "100%", marginTop: "10px" }}
                    cols={1}
                    rowHeight={164}
                   >
                    {previewFiles.map((img, index) => (
                      <ImageListItem
                        key={index}
                        className="imageListItem"
                      >
                        <img
                          src={img}
                          alt={`uploaded ${index}`}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                        <IconButton
                          aria-label="delete"
                          className="deleteIcon"
                          onClick={(e)=>{
                             dispatch(handleDeleteFiles(index));   
                          }}                   
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>
              </Paper>
            </Grid>
            <Grid item md={4} xs={12}>
              <Paper elevation={2} className="banrright"></Paper>
            </Grid>
          </Grid>
        </AdminLayout>
      </form>
    </>
  );
};

export default AdminBannerPage;
