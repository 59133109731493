import React from "react";
import "./adminproduct.scss";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import noimage from "../../Assets/noimage.png";

const MainProductList = () => {
  return (
    <>
      <Grid container spacing={1}>
      <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <TextField
              label="Search"
              size="small"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        <Grid item md={12} xs={12}>
          <ul>
            {Array.from({ length: 25 }).map((element, index) => {
                let bgcolor="#fff";
                   if(index % 2==0){bgcolor="aliceblue";}
              return (
                <>
                <li className="mainprod-card" key={index} style={{backgroundColor:bgcolor}}>                  
                    <div className="row">
                      <div className="imgcon">
                        <img src={noimage}/>
                      </div>
                      <h5>Product Name {index}</h5>
                    </div>
                    <div className="del">
                        <DeleteIcon sx={{fontSize:"14px"}}/>
                    </div>
                </li>
               
                    </>
              );
            })}
          </ul>
        </Grid>
      </Grid>
    </>
  );
};

export default MainProductList;
