/* eslint-disable react/jsx-no-undef */
import React from "react";
import "./index.css";
import store from "../src/API/Store/store";
import { render } from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  unstable_HistoryRouter as Router,
} from "react-router-dom";
import { Provider } from "react-redux";
import HomePage from "./Components/HomePage/HomePage";
import AdminProductPage from "./Components/AdminProduct/AdminProductPage";
import AdminBannerPage from "./Components/AdminBanner/AdminBannerPage";
import CategoryProductPage from "./Components/Product/CategoryProductPage";
import ProductPage from "./Components/Product/ProductPage";
 


const root = document.getElementById("root");
render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
           <Route path="/" element={<HomePage/>}/>
           <Route path="/products" element={<CategoryProductPage/>}/>
           <Route path="/products/xasd745sd" element={<ProductPage/>}/>

           
           <Route path="/admin/product" element={<AdminProductPage/>}/>
           <Route path="/admin/banner" element={<AdminBannerPage/>}/>
                         
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  root
);

// s
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
