import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useNavigate } from "react-router-dom";
import "./CategoryList.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Grid, Typography } from "@mui/material";

const CategoryList = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const url =
    "https://ace.playspgames.com/TEE BLANKA/3 PRODUCTS/19 TBKA RUFFTY TIPPING - MALE/";
  const images = [
    `${url}RT-01 • BLACK with WHITE TIP • RUFFTY TIPPING • POLO COLLAR • COTTON • 260 GSM.jpg`,
    `${url}RT-13 • RED with BLACK TIP • RUFFTY TIPPING • POLO COLLAR • COTTON • 260 GSM.jpg`,
    `${url}RT-25 • ELECTRIC BLUE with WHITE TIP • RUFFTY TIPPING • POLO COLLAR • COTTON • 260 GSM.jpg`,
    `${url}RT-21 • CREAM with BLACK TIP • RUFFTY TIPPING • POLO COLLAR • COTTON • 260 GSM.jpg`,
    `${url}RT-22 • SUNFLOWER YELLOW with BLACK TIP • RUFFTY TIPPING • POLO COLLAR • COTTON • 260 GSM.jpg`,
    `${url}RT-30 • OLIVE GREEN with WHITE TIP • RUFFTY TIPPING • POLO COLLAR • COTTON • 260 GSM.jpg`,
  ];

  return (
    <Box sx={{ flexGrow: 1, p: { md: 2, sm: 2, xs: 1 } }}>
    <Grid container spacing={2} justifyContent="center">
      {Array.from({ length: 6 }).map((_, index) => {
        const randomIndex = Math.floor(Math.random() * images.length);
        const imageUrl = images[randomIndex];
        return (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "10px",
                cursor: "pointer",
                margin: "10px",
                transition: "all 0.3s ease",
                padding: "0", // Initially no padding
                "&::before, &::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  height: "100%",
                  width: "0px",  // Initially no border
                  backgroundColor: "#136E95", // Border color
                  transition: "width 0.3s ease",
                },
                "&::before": {
                  left: 0,
                },
                "&::after": {
                  right: 0,
                },
                "&:hover": {
                  padding: "10px",  // Padding on hover
                  "&::before, &::after": {
                    width: "3px",  // Border width on hover
                  },
                },
              }}
              onClick={() => navigate("/products")}
            >
              <Box
                component="img"
                src={`${imageUrl}?w=248&fit=crop&auto=format`}
                alt={`Product ${index}`}
                sx={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",  // Prevent zoom effect
                  transition: "transform 0.3s ease",  // Smooth image transformation
                }}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  </Box>
  );
};

export default CategoryList;
