import { configureStore } from "@reduxjs/toolkit";
import confirmDialogReducer from '../Reducer/confirmDialogReducer';
import alertReducer from "../Reducer/alertReducer";
import errorReducer from "../Reducer/errorReducer";
import userReducer from "../Reducer/userReducer";
import menuReducer from "../Reducer/menuReducer";
import adminProductReducer from "../Reducer/adminProductReducer";
import fileReducer from "../Reducer/fileReducer";
 
const store = configureStore({
    reducer: {
        confirm:confirmDialogReducer,
        alert:alertReducer,
        error:errorReducer,
        user:userReducer,
        menu:menuReducer,
        adminproduct:adminProductReducer,
        file:fileReducer,
       
    }
});

export default store;