import React from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./ProductCardList.scss";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Box,
  Grid,
} from "@mui/material";

const ProductCardList = () => {
  const navigate = useNavigate();
  const url =
    "https://ace.playspgames.com/TEE BLANKA/3 PRODUCTS/12 TBKA Dull Dryfit Polos FOREST CLUB/";
  const images = [
    `${url}ALL COLOR 1 • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}ALL COLOR 2 • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}DD-1 • RED • WHITE TIP • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}DD-2 • ORANGE • BLACK TIP • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}DD-7 • BEIGE • BLACK TIP • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}DD-3 • NAVY BLUE • WHITE TIP • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}DD-17 • WHITE • ROYAL TIP • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}DD-20 • INDIAN BLUE • WHITE TIP • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}DD-14 • PARROT GREEN • WHITE TIP • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}DD-11 • BLACK • WHITE TIP • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}DD-9 • G.YELLOW • BLACK TIP • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}DD-26 • BOTTLE GREEN • WHITE TIP • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
    `${url}ALL COLOR 3 • FOREST CLUB • DULL DRYFIT • POLO • 200GSM.jpg`,
  ];

  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box sx={{ flexGrow: 1, p: { md: 2, sm: 2, xs: 2 } }}>
      <Grid container spacing={2} justifyContent="center">
        {Array.from({ length: 6 }).map((_, index) => {
          const randomIndex = Math.floor(Math.random() * images.length);
          const imageUrl = images[randomIndex];
          return (
            <Grid item xs={12} sm={6} md={4} key={index} p={1}>
              <Card
                sx={{
                  maxWidth: "100%",
                  borderRadius: 3,
                  boxShadow: 3,
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: '0px 8px 15px rgba(0,0,0,0.1)',
                  },
                  cursor:"pointer"
                }}
              >
                {/* Product Image */}
                <CardMedia
                  component="img"
                  image={`${imageUrl}?w=248&fit=crop&auto=format`}
                  alt={`Product ${index}`}
                  sx={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    borderRadius: '8px 8px 0 0',
                  }}
                />

                {/* Product Details */}
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Product Name
                  </Typography>

                  {/* Price and Wishlist Icon */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mt: 1,
                    }}
                  >
                    <Typography variant="body1" color="text.secondary">
                      $69
                    </Typography>
                    <IconButton
                      aria-label="add to wishlist"
                      sx={{
                        color: '#D24838',
                        transition: 'color 0.3s ease',
                        '&:hover': { color: '#ff4a4a' },
                      }}
                    >
                      <FavoriteBorderIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ProductCardList;
