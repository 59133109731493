import React from "react";
import "./UserLargeScreenLogin.css";
import wave from "../../Assets/login_wave.svg";
import { Box, InputAdornment, Paper, TextField } from "@mui/material";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LockIcon from "@mui/icons-material/Lock";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import mobbtm from "../../Assets/mob_btm.svg";
import CloseIcon from "@mui/icons-material/Close";
const UserMobileLogin = () => {
  return (
    <>
      <Paper
      elevation={3}
        sx={{
          flex: 1,
          position: "relative",
          flexDirection: "column",
          height: "auto",
          // width:"300px"
        }}
      >
     
        {/* Wave image at the top */}
        <Box sx={{ flex: 1 }}>
          <img
            src={wave}
            alt="wave"
            style={{
              width: "100%",
              objectFit: "contain",
              height: "auto",
              position: "relative", // Sticky isn't needed here
            }}
          />
        </Box>
        <Box sx={{position:"absolute",top:0,left:0,border:"2px solid #fff",
        borderTop:"none",borderLeft:"none",display:"flex",justifyContent:"center",
        alignItems:"center",padding:"5px"
        }}>
            <CloseIcon sx={{color:"#000"}}/>
        </Box>
        {/* Sign In section */}
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            padding: "30px",
            gap: "20px",
          }}
        >
          <p className="mob-welcome">Welcome</p>
          <p className="mob-signin">Sign in to your account</p>

          {/* Mobile Number input */}
          <TextField
            size="small"
            fullWidth
            label="Mobile Number"
            variant="standard"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneIphoneIcon />
                </InputAdornment>
              ),
            }}
          />

          {/* Password input */}
          <TextField
            size="small"
            fullWidth
            label="Password"
            variant="standard"
            type="password" // should be password instead of text for security
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />

          {/* Sign In Button */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box component="button" type="button" className="mob-button">
              <ArrowForwardIcon sx={{color:"#fff"}} />
            </Box>
          </Box>
        </Box>

        {/* Bottom image */}
        <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <img
            src={mobbtm}
            alt="bottom"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              height: "120px",
            }}
          />
        </Box>
      </Paper>
    </>
  );
};

export default UserMobileLogin;
