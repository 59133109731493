import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { IconButton, ImageList, ImageListItem } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './NewImagesUpload.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { handlePreviewFiles, handleUploadFiles } from '../../API/Reducer/fileReducer';

const NewImagesUpload = () => {
  const dispatch=useDispatch();
  const { files,previewFiles } = useSelector((state) => state.file);
  const { control, handleSubmit } = useForm();  
  const [images, setImages] = useState([]);
  const [filesL, setFilesL] = useState([]);


  const onSubmit = (data) => {
    // Handle form submission
  };

  const handleImageChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const newImageUrls = newFiles.map(file => URL.createObjectURL(file));
    const prevImages=[...previewFiles,...newImageUrls];
    const orgFiles=[...files,...newFiles]; 
    
    dispatch(handlePreviewFiles(prevImages));
    dispatch(handleUploadFiles(orgFiles));
  };
   
  
  const triggerFileUpload = () => {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'uploadFile' }));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="images"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <>
            <input
              type="file"
              multiple
              accept="image/*"
              className={styles.fileInput}
              id="fileInput"
              name="files[]"
              onChange={(e) => {
                handleImageChange(e);
                field.onChange(e);
              }}
            />
            <label htmlFor="fileInput" className={styles.fileInputLabel} >
              <CloudUploadIcon className={styles.fileInputIcon} />
                Upload
            </label>
          </>
        )}
      />
      
      
    </form>
  );
};

export default NewImagesUpload;
