import React from "react";
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Modal,
  Paper,
  TextField,
} from "@mui/material";
import "./adminproduct.scss";
import { useForm } from "react-hook-form";
import noimage from "../../Assets/noimage.png";
import {
  handleMaxLengthInput,
  handleUserGetLocalStorage,
} from "../../MIS/Global";
import AlertComp from "../AlertMessageCom/AlertComp";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import FabricList from "./FabricList";
import SizeList from "./SizeList";
import { useDispatch, useSelector } from "react-redux";
import { handleFabricModal, handleSizeModal } from "../../API/Reducer/adminProductReducer";
import FabricMaster from "./FabricMaster";
import SizeMaster from "./SizeMaster";
import MainProductList from "./MainProductList";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border:"none",
  p: 1,
};
const MainProduct = () => {
  const dispatch = useDispatch();
  const {fabricModal,sizeModal } = useSelector((state) => state.adminproduct);
  const {
    register,
    handleSubmit,    
    setValue,
    getValues,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      mpid: 0,
      mpname: "",
      imgpath: "",
      loginid: handleUserGetLocalStorage("loginid"),
    },
  });

  const onSubmit = (data) => {};
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  return (
    <>
      <AlertComp />
      <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item md={9} xs={12}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button color="success" variant="contained">
                  SAVE
                </Button>
              </Grid>
              <Grid md={4} xs={12}>
                <div className="mpimgcon">
                  <img src={noimage} alt="Image" srcset="" />
                </div>
              </Grid>
              <Grid md={4} xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  label="Main Product Name"
                  inputProps={{
                    onInput: (event) => handleMaxLengthInput(event, 150),
                  }}
                  {...register("mpname", {
                    required: "Product name is required",
                    minLength: {
                      value: 3,
                      message:
                        "Product Name must be at least 3 characters long",
                    },
                  })}
                  error={!!errors.mpname}
                  helperText={errors.mpname?.message}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Paper sx={{ padding: "10px" }} elevation={3}>
                  <div className="mpfabric">
                    <div className="row1">
                      <h3>Select Fabric:</h3>
                      <div className="row2">
                        <TextField
                          size="small"
                          fullWidth
                          label="Search"
                          variant="standard"
                          inputProps={{
                            onInput: (event) =>
                              handleMaxLengthInput(event, 150),
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Button
                          color="success"
                          variant="contained"
                          size="small"
                          type="button"
                          onClick={()=>{dispatch(handleFabricModal())}}
                        >
                          <AddIcon /> Add
                        </Button>
                      </div>
                    </div>
                    <div style={{width:"100%"}}>
                      <hr className="styled-hr"></hr>
                    </div>
                    <div>
                      <FabricList />
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item md={12} xs={12}>
                <Paper sx={{ padding: "10px" }} elevation={3}>
                  <div className="mpfabric">
                    <div className="row1">
                      <h3>Select Size:</h3>
                      <div className="row2">
                        <TextField
                          size="small"
                          fullWidth
                          label="Search"
                          variant="standard"
                          inputProps={{
                            onInput: (event) =>
                              handleMaxLengthInput(event, 150),
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Button
                          color="success"
                          variant="contained"
                          size="small"
                          type="button"
                          onClick={()=>{dispatch(handleSizeModal())}}
                        >
                          <AddIcon/> Add
                        </Button>
                      </div>
                    </div>
                    <div style={{width:"100%"}}>
                    <hr className="styled-hr"></hr>                    
                    </div>
                    <div>
                      <SizeList/>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xs={12}>
           <Paper elevation={3} sx={{padding:"10px",maxHeight:"100vh",overflow:"hidden",overflowY:"scroll"}}>
             <MainProductList/>
           </Paper>

          </Grid>
        </Grid>
      </form>

      <Modal
        open={fabricModal}
        onClose={()=>{dispatch(handleFabricModal())}}       
      >
        <Box sx={style}>
           <FabricMaster/>
         </Box>
      </Modal>
      <Modal
        open={sizeModal}
        onClose={()=>{dispatch(handleSizeModal())}}       
      >
        <Box sx={style}>
           <SizeMaster/>
         </Box>
      </Modal>
   9</>
  );
};

export default MainProduct;
