import React from "react";
import Grid from "@mui/material/Grid";
import "./Footer.scss";
import { Button, Divider, TextField } from "@mui/material";
export const Footer = () => {
  return (
    <>
      <Grid
        container
        spacing={1}
        sx={{ backgroundColor: "#19202C", padding: "10px" }}
      >
        <Grid item md={3} xs={12}>
          <div className="footer-heading">
            <span>Jain&nbsp;</span>
            <span>Screen</span>
          </div>
          <p className="footer-descp">
            Our collections are fashionable at an affordable price without
            compromising quality, always in style and with global latest outlook
            trends
          </p>
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <div className="footer-main">
            <div className="footer-subheading">
              <span>Shop</span>
            </div>
            <p className="footer-info">Screen Printing</p>
            <p className="footer-info">DTG</p>
            <p className="footer-info">Heat Press Printing</p>
            <p className="footer-info">Dye Sublimation</p>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <div className="footer-main">
            <div className="footer-subheading">
              <span>Links</span>
            </div>
            <p className="footer-info">About Us</p>
            <p className="footer-info">Contact Us</p>
            <p className="footer-info">Terms & Conditions</p>
            <p className="footer-info">Privacy & Policies</p>
          </div>
        </Grid>
        <Grid item md={3} xs={12}>
          <div className="footer-main">
            <div className="footer-subheading">
              <span>Enquiry</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "5px",
                marginBottom:"10px"
              }}
            >
              <TextField
                label="Name"
                defaultValue=""
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white", // White border color
                    },
                    "&:hover fieldset": {
                      borderColor: "white", // White border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white", // White border color when focused
                    },
                    '& input': {
                      color: 'white', // White text color
                     },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white", // White label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white", // White label color when focused
                  },
                }}
              />

              <TextField
                type="number"
                label="Mobile Number"
                defaultValue=""
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white", // White border color
                    },
                    "&:hover fieldset": {
                      borderColor: "white", // White border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white", // White border color when focused
                    },
                    '& input': {
                      color: 'white', // White text color
                     },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white", // White label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white", // White label color when focused
                  },
                }}
              />
            </div>
            <div
              style={{
                display: "flex",               
                marginBottom:"10px"
              }}
            >
            <TextField
                type="text"
                label="Description"
                defaultValue=""
                size="small"
                multiline
                maxRows={2}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white", // White border color
                    },
                    "&:hover fieldset": {
                      borderColor: "white", // White border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white", // White border color when focused
                    },
                    '& input': {
                      color: 'white', // White text color
                     },
                     '& textarea': {
                      color: 'white', // White text color
                     },
                  },
                  "& .MuiInputLabel-root": {
                    color: "white", // White label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "white", // White label color when focused
                  },
                }}
              />
            </div>

            <div
              style={{
                display: "flex",               
                padding:"10px",
                justifyContent:"end",
                alignItems:"center",
              }}
            >
                <Button variant="contained" color="success">Submit</Button>
            </div>

          </div>
        </Grid>
        <Grid item md={12} xs={12}>
         <Divider sx={{ bgcolor: '#FDFEFE' }}/>
        </Grid>
        <Grid item md={12} xs={12} className='footer-copywrite'>
          ©2024 JainScreen, All Rights Reserved.
        </Grid>

      </Grid>
    </>
  );
};
