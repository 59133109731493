import React from "react";
import Grid from "@mui/material/Grid";
import "./adminproduct.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { handleSizeModal } from "../../API/Reducer/adminProductReducer";
import { useForm } from "react-hook-form";
import {
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { handleMaxLengthInput } from "../../MIS/Global";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

const SizeMaster = () => {
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.adminproduct);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      mpid: 0,
      mpname: "",
      imgpath: "",
    },
  });

  const onSubmit = (data) => {};
  const onError = (errors) => {
    const firstErrorField = Object.keys(errors)[0];
    setFocus(firstErrorField);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
        <Grid
          container
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <Grid item md={12} xs={12} className="fabmaster-con">
            <h3>Size Master</h3>
            <div>
              <div
                className="closeRound"
                onClick={() => {
                  dispatch(handleSizeModal());
                }}
              >
                <CloseIcon />
              </div>
            </div>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Size Name"
              inputProps={{
                onInput: (event) => handleMaxLengthInput(event, 150),
              }}
              {...register("mpname", {
                required: "Size name is required",
              })}
              error={!!errors.mpname}
              helperText={errors.mpname?.message}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button color="success" variant="contained" size="small">
              Save
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <TextField
              label="Search"
              size="small"
              variant="standard"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" sx={{ cursor: "pointer" }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{ maxHeight: 250, border: "none" }}
            >
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "600" }}>
                      Fabric Name
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from({ length: 10 }).map((element, index) => {
                    return (
                      <TableRow>
                        <TableCell>Size Name {index}</TableCell>
                        <TableCell sx={{ cursor: "pointer" }}>
                          <EditIcon />
                          <DeleteIcon />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SizeMaster;
