import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import "./AppBarComponent.scss";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../Assets/jc_logo_tr.png";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ContactBar from "./ContactBar";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoginIcon from "@mui/icons-material/Login";
import UserLargeScreenLogin from "../Login/UserLargeScreenLogin";
import UserMobileLogin from "../Login/UserMobileLogin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 0,
  m: 0,
};
function AppBarComponent() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => {
    setOpen(!open);
  };
  const [mopen, setMOpen] = React.useState(false);
  const handleOpen = () => setMOpen(true);
  const handleClose = () => setMOpen(false);

  return (
    <>
      {/* <AppBar position="static" className='app-bar'>         
      <Toolbar className='appbar-main'>        
          <div className="appbar-row">
            
            <div className="row" onClick={(e)=>{navigate("/")}}>
            <div className='appbar-imgcon'>
              <img src={logo} className='appbar-img'/>
            </div>
              <span className="appbar-heading">Teelelo</span>
            </div>

             <div className="row">
              <div className="appbar-iconcon" onClick={(e)=>{toggleDrawer()}}>
               <MenuIcon style={{color:"#000"}}/>
              </div>
             </div> 

          </div>
        
      </Toolbar>
    </AppBar> */}

      <AppBar position="static" sx={{ backgroundColor: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#000",
            alignItems: "center",
            justifyContent: isSmallScreen ? "flex-end" : "space-between",
            padding: "10px",
          }}
        >
          <div style={{ display: isSmallScreen ? "none" : "block" }}>
            <span>Welcome To Teelelo</span>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px",cursor:"pointer" }}>
              <span
                style={{
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                WishList
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FavoriteBorderIcon sx={{ color: "#fff", fontSize: "18px" }} />
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "5px",cursor:"pointer" }}>
              <span
                style={{
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Login
              </span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <LoginIcon sx={{ color: "#fff", fontSize: "18px" }} />
              </span>
            </div>
          </div>
        </Box>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => navigate("/")}>
            <img
              src={logo}
              alt="Teelelo Logo"
              style={{ height: 40, marginRight: 8 }}
            />
             
              <Typography variant="h6" sx={{ color: "#000" }}>
                Teelelo
              </Typography>
          
          </IconButton>

          <IconButton edge="end" color="#000" onClick={() => toggleDrawer()}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={() => {
          toggleDrawer();
        }}
      >
        <Box sx={{ width: isMobile ? "350px" : "400px" }}>
          <ContactBar setClose={setOpen} />
        </Box>
      </Drawer>

      <Modal open={mopen} onClose={handleClose}>
        <Box sx={style}>
          {isMobile ? <UserMobileLogin /> : <UserLargeScreenLogin />}
        </Box>
      </Modal>
    </>
  );
}
export default AppBarComponent;
